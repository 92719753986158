import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  const echoWebSocket = new Echo({
    broadcaster: config.public.uhEcho.broadcaster,
    client: new Pusher(config.public.uhEcho.pusherAppKey, {
      enableStats: false,
      forceTLS: config.public.uhEcho.forceTLS,
      cluster: config.public.uhEcho.pusherAppCluster,
      channelAuthorization: {
        customHandler: (requestParams, callback) => {
          useApiFetch(config.public.uhEcho.authEndpoint, {
            baseURL: config.public.uhEcho.baseUrl,
            method: 'POST',
            query: {
              socket_id: requestParams.socketId,
              channel_name: requestParams.channelName,
            },
            onResponse({ response }) {
              callback(null, response._data);
            },
            onRequestError({ error }) {
              callback(error);
            },
          });
        },
      },
    }),
  });

  nuxtApp.provide('echo', echoWebSocket);
});
