<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { useCheckoutStore } from '~/modules/checkout/stores';

const { user } = useAuth();
const showBillingForm = ref<boolean>(false);
const store = useCheckoutStore();
const { billingDetails } = storeToRefs(store);

const rules = ref({
  cardHolderName: {
    required: helpers.withMessage("Enter the cardholder's name", required),
  },
  addressLine1: {
    required: helpers.withMessage('Billing address is required', required),
  },
  addressLine2: {},
  email: {
    required: helpers.withMessage('Email address is required', required),
  },
  city: {
    required: helpers.withMessage('City is required', required),
  },
  postcode: {
    required: helpers.withMessage('Postcode is required', required),
  },
});

onMounted(() => {
  billingDetails.value.cardHolderName = `${user.first_name} ${user.last_name}`;
  billingDetails.value.email = user.email;
  billingDetails.value.addressLine1 = user.address_1;
  billingDetails.value.addressLine2 = user.address_2;
  billingDetails.value.city = user.city;
  billingDetails.value.postcode = user.postcode;
});

const $form = useVuelidate(rules, billingDetails);

const fullAddress = computed<string>(
  () =>
    `${billingDetails.value.addressLine1}${
      billingDetails.value.addressLine2
        ? ' ' + billingDetails.value.addressLine2
        : ''
    }, ${billingDetails.value.city}, ${billingDetails.value.postcode}`,
);
</script>

<template>
  <div>
    <h2 class="mb-4 text-base font-semibold">Billing details</h2>

    <div v-if="!showBillingForm">
      <div>{{ billingDetails.cardHolderName }}</div>
      <address class="mb-4">{{ fullAddress }}</address>

      <button
        type="button"
        class="underline hover:no-underline"
        @click="showBillingForm = true"
      >
        Incorrect billing details?
      </button>
    </div>

    <div v-else class="flex flex-wrap gap-4">
      <div class="grid w-full grid-cols-1 gap-4 sm:grid-cols-2">
        <div class="w-full">
          <UhInput
            v-model="billingDetails.cardHolderName"
            :required="true"
            name="cardHolderName"
            label="Cardholder Name"
            placeholder=""
            :invalid="$form.cardHolderName.$invalid"
            :error-message="$form.cardHolderName.required.$message"
          >
          </UhInput>
        </div>

        <div class="w-full"></div>
      </div>

      <div class="w-full">
        <UhInput
          v-model="billingDetails.addressLine1"
          :required="true"
          name="addressLine1"
          label="Address"
          place-holder="Street address and number"
          :invalid="$form.addressLine1.$invalid"
          :error-message="$form.addressLine1.required.$message"
        >
        </UhInput>
      </div>

      <div class="w-full">
        <UhInput
          v-model="billingDetails.addressLine2"
          :required="false"
          name="addressLine2"
          label=""
          place-holder="Floor, apartment, unit, building (Optional)"
          :invalid="$form.addressLine2.$invalid"
        >
        </UhInput>
      </div>

      <div class="grid w-full grid-cols-1 gap-4 sm:grid-cols-2">
        <div class="w-full">
          <UhInput
            v-model="billingDetails.city"
            :required="true"
            name="city"
            label="City"
            place-holder=""
            :invalid="$form.city.$invalid"
            :error-message="$form.city.required.$message"
          >
          </UhInput>
        </div>

        <div class="w-full">
          <UhInput
            v-model="billingDetails.postcode"
            :required="true"
            name="postcode"
            label="Postcode"
            place-holder=""
            :invalid="$form.postcode.$invalid"
            :error-message="$form.postcode.required.$message"
          >
          </UhInput>
        </div>
      </div>
    </div>
  </div>
</template>
