import { h, type FunctionalComponent } from 'vue';
import { cva, type VariantProps } from 'class-variance-authority';

const heading = cva('font-semibold text-gray-900 dark:text-white', {
  variants: {
    lvl: {
      '1': 'mb-2 mt-2 text-2xl sm:text-3xl',
      '2': 'mb-2 mt-2 text-xl sm:text-2xl',
      '3': 'mt-1 text-lg sm:text-xl',
      '4': 'text-base sm:text-lg',
      '5': 'text-base ',
      '6': 'text-sm ',
    },
  },
});

type HeadingClassProps = VariantProps<typeof heading>;

type HeadingProps = {
  lvl: HeadingClassProps['lvl'];
  class?: string;
};

const UhHeading: FunctionalComponent<HeadingProps> = (props, context) => {
  return h(
    `h${props.lvl}`,
    { class: heading({ lvl: props.lvl }) },
    context.slots.default()
  );
};

UhHeading.props = {
  lvl: {
    type: [String, Number],
    required: false,
    default: 2,
  },
};

export default UhHeading;
