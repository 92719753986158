import RayPlugin from 'vue-ray';

export default defineNuxtPlugin((nuxtApp) => {
  if (!process.env.RAY_ENABLED) return;

  nuxtApp.vueApp.use(RayPlugin, {
    interceptErrors: true,
    host: '127.0.0.1',
    port: 23517,
  });
});
