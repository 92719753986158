export const HTTP_STATUS_OK = 200;
export const HTTP_GET = 'GET';
export const HTTP_POST = 'POST';
export const HTTP_PUT = 'PUT';
export const HTTP_DELETE = 'DELETE';

export const TABLE_LOADING_DELAY = 250;

export const PROPERTY_STACK = 640480742;
export const TEST_ESTATES = 750950769;
export const CONVERSION_RATE_TOOLTIP =
  'The percentage of property clicks that resulted in an enquiry.';
