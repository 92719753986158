<template>
  <div class="w-fit rounded-lg border border-gray-200 lg:w-full">
    <table class="w-full text-left text-gray-500 dark:text-gray-400">
      <thead
        class="font-semibold text-gray-900 dark:bg-gray-700 dark:text-gray-300"
      >
        <tr class="border-b border-gray-200">
          <slot name="head"></slot>
        </tr>
      </thead>
      <tbody>
        <slot name="body"></slot>
      </tbody>
    </table>
  </div>
</template>
